// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery");
window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// require("bootstrap/dist/js/bootstrap")
require('vuze/scripts');

import '../datatables/users';
import '../datatables/credit_transactions';
import '../datatables/projects';
import '../datatables/organizations';
import '../datatables/building_plans';
import '../datatables/sections';
import '../datatables/defects';
import '../datatables/reports';

require('datatables.net-bs')(window, $);
require('datatables.net-buttons-bs')(window, $);
require('datatables.net-buttons/js/buttons.colVis.js')(window, $);
require('datatables.net-buttons/js/buttons.html5.js')(window, $);
require('datatables.net-buttons/js/buttons.print.js')(window, $);
require('datatables.net-responsive-bs')(window, $);
require('datatables.net-select')(window, $);
require('datatables.net-bs4')(window, $);

require("@nathanvda/cocoon")

import '../styles/application';

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
