$( document ).on('turbolinks:load', function() {
  if ($('#organizations-datatable_wrapper').length == 0 ) {
    $('#organizations-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        // "stateSave": true,
        "ajax": {
            "url": $('#organizations-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "id"},
            {"data": "name"},
            {"data": "number"},
            {"data": "address"},
            {"data": "phone_number"},
            {"data": "action", "orderable": false}
        ],
        order: [[0, 'desc']]
    });
  }
});
