$( document ).on('turbolinks:load', function() {
  if ($('#projects-datatable_wrapper').length == 0 ) {
    $('#projects-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        // "stateSave": true,
        "ajax": {
            "url": $('#projects-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "id"},
            {"data": "name"},
            {"data": "address"},
            {"data": "client_name"},
            {"data": "author"},
            {"data": "date_created"},
            {"data": "status"},
            {"data": "action", "orderable": false}
        ],
        order: [[0, 'desc']]
    });
  }
});
