$( document ).on('turbolinks:load', function() {
  if ($('#defects-datatable_wrapper').length == 0 ) {
      let columns = [];
      $('#defects-datatable thead tr th').each(function(th) {
          columns.push({
            data: $(this).data('column'),
            orderable: $(this).data('column-orderable')
          })
      })

    $('#defects-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": $('#defects-datatable').data('source')
        },
        "pagingType": "full_numbers",
        // "columns": [
        //     {"data": "id"},
        //     { "data": "picture", "orderable": false },
        //     {"data": "recommendation"},
        //     {"data": "condition"},
        //     {"data": "decorative_condition"},
        //     {"data": "comment"},
        //     {"data": "action", "orderable": false}
        // ],
        "columns": columns,
        order: [[0, 'asc']],
        "columnDefs": [
          {"className": "dt-center", "targets": "_all"}
        ]
    });
  }
});
