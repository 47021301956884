$( document ).on('turbolinks:load', function() {
  if ($('#sections-datatable_wrapper').length == 0 ) {
    $('#sections-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": $('#sections-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "id"},
            {"data": "name"},
            {"data": "label"},
            {"data": "defects_count", "orderable": false},
            {"data": "status"},
            {"data": "action", "orderable": false}
        ],
        order: [[0, 'asc']],
        "columnDefs": [
          {"className": "dt-center", "targets": "_all"}
        ]
    });
  }
});
