$( document ).on('turbolinks:load', function() {
  if ($('#users-datatable_wrapper').length == 0 ) {
    $('#users-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        // "stateSave": true,
        "ajax": {
            "url": $('#users-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "id"},
            {"data": "email"},
            {"data": "full_name"},
            {"data": "organization_name"},
            {"data": "role"},
            {"data": "action", "orderable": false}
        ],
        order: [[0, 'desc']]
    });
  }
});
