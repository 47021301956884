$( document ).on('turbolinks:load', function() {
    if ($('#credit-transactions-datatable_wrapper').length == 0 ) {
        $('#credit-transactions-datatable').dataTable({
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#credit-transactions-datatable').data('source')
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "created_at"},
                {"data": "description"},
                {"data": "subject"},
                {"data": "amount"},
                {"data": "balance"},
            ],
            order: [[0, 'desc']],
            "columnDefs": [
                {"className": "dt-center", "targets": "_all"}
            ]
        });
    }
});
