$( document ).on('turbolinks:load', function() {
  if ($('#building_plans-datatable_wrapper').length == 0 ) {
    $('#building_plans-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": $('#building_plans-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "id"},
            {"data": "name"},
            {"data": "action", "orderable": false}
        ],
        order: [[0, 'desc']],
        "columnDefs": [
          {"className": "dt-center", "targets": "_all"}
        ]
    });
  }
});
